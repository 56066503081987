<template>
	<div
		v-if="data"
		class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] overflow-y-auto max-h-[470px] scrollbar-blue"
	>
		<template>
			<div class="w-full flex justify-between items-center gap-[10px] mb-[24.18px]">
				<div class="font-main font-semibold text-[18px] text-third uppercase">
					{{ $t('titles.catch_permissions') }}
				</div>
			</div>
			<div>
				<div v-for="(fish, fishInd) in data" :key="fish.fish_id">
					<div
						class="flex gap-[10px] mb-[10px] font-main font-medium text-[14px] text-second leading-[1.43]"
					>
						<span>{{ fishInd + 1 }}</span>
						<span>{{ fish.fishName }}</span>
					</div>
					<div
						:class="{ 'mb-[30px]': fishInd < data.length - 1 }"
						class="grid grid-cols-1 md:grid-cols-4 gap-[22px]"
					>
						<div
							class="flex items-center justify-between gap-[10px] pb-[11px] border-b-[2px] border-b-solid border-b-[#FFA800]"
						>
							<span class="font-main font-medium text-[12px] text-second leading--[1.66]">{{
								$t('labels.quato')
							}}</span>
							<span class="font-main font-bold text-[16px] text-second leading--[1.25]"
								>{{ fish.quota_total_value | formatNumber }} кг</span
							>
						</div>
						<div
							class="flex items-center justify-between gap-[10px] pb-[11px] border-b-[2px] border-b-solid border-b-[#FFA800]"
						>
							<span class="font-main font-medium text-[12px] text-second leading--[1.66]">{{
								$t('labels.permissions')
							}}</span>
							<span class="font-main font-bold text-[16px] text-second leading--[1.25]"
								>{{ fish.permission_total_value | formatNumber }} кг</span
							>
						</div>
						<div
							class="flex items-center justify-between gap-[10px] pb-[11px] border-b-[2px] border-b-solid border-b-[#00E096]"
						>
							<span class="font-main font-medium text-[12px] text-second leading--[1.66]">{{
								$t('labels.quota_all')
							}}</span>
							<span class="font-main font-bold text-[16px] text-second leading--[1.25]"
								>{{ fish.trade_total_value | formatNumber }} кг</span
							>
						</div>
						<div
							class="flex items-center justify-between gap-[10px] pb-[11px] border-b-[2px] border-b-solid border-b-[#0095FF]"
						>
							<span class="font-main font-medium text-[12px] text-second leading--[1.66]">{{
								$t('labels.remainder_permissions') + ' | %'
							}}</span>
							<span class="font-main font-bold text-[16px] text-second leading--[1.25]">{{
								(100 - fish.percentage_permission_trade) | formatNumber
							}}</span>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	name: 'CatchPermissions',
	props: {
		userTag: {
			type: String,
		},
	},
	data() {
		return {
			data: null,
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				const response = await api.get(`/dashboard/${this.userTag}/percentage-trade-fish`)

				if (response?.data?.data) {
					this.data = response?.data?.data
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>
