var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.statData)?_c('div',{staticClass:"bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] grid grid-cols-1 gap-[10px] lg:grid-cols-3"},[[_c('div',{staticClass:"flex flex-col items-start relative rounded-[16px] py-[23px] px-[20px] grow min-h-[207px] hover:no-underline",style:({
				background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #ACD2F9 0%, #DDEEFF 100%)`,
			})},[_c('svg',{class:'block w-[40px] h-[40px] mb-[11px]',attrs:{"viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('circle',{attrs:{"cx":"20","cy":"20","r":"20","fill":"#52A5FC"}}),_c('path',{attrs:{"d":"M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z","fill":"#fff"}})]),_c('div',{staticClass:"font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px] no-underline"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(Number(_vm.statData.sum_total_fish_quota)))+" кг ")]),_c('div',{staticClass:"grow font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px] no-underline"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.my_quota.application.quota.text'))+" ")]),_c('div',{staticClass:"mt-2 text-[12px] italic no-underline"},[_vm._v(" "+_vm._s(_vm.$t('headers.data_start_20240701'))+" ")])]),_c('router-link',{staticClass:"flex flex-col items-start relative rounded-[16px] py-[23px] px-[20px] grow hover:no-underline",style:({
				background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #FFF4DE 0%, #FEE8BD 100%)`,
			}),attrs:{"to":_vm.getLicenseLink}},[_c('svg',{class:'block w-[40px] h-[40px] mb-[11px]',attrs:{"viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('circle',{attrs:{"cx":"20","cy":"20","r":"20","fill":"#52A5FC"}}),_c('path',{attrs:{"d":"M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z","fill":"#fff"}})]),_c('div',{staticClass:"font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(Number(_vm.statData.sum_total_fish_permission)))+" кг ")]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(Number(_vm.statData.percentage_quota_permission)))+" %")])]),_c('div',{staticClass:"grow font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px]"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.my_quota.application.permission.text'))+" ")]),_c('div',{staticClass:"mt-2 text-[12px] italic"},[_vm._v(" "+_vm._s(_vm.$t('headers.data_start_elicense_20240701'))+" ")])]),_c('router-link',{staticClass:"flex flex-col items-start relative rounded-[16px] py-[23px] px-[20px] grow hover:no-underline",style:({
				background: `radial-gradient(71.01% 71.01% at 82.36% 28.99%, #DCFCE7 0%, #EDFFF3 100%)`,
			}),attrs:{"to":_vm.getTradeLink}},[_c('svg',{class:'block w-[40px] h-[40px] mb-[11px]',attrs:{"viewBox":"0 0 40 40","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('circle',{attrs:{"cx":"20","cy":"20","r":"20","fill":"#52A5FC"}}),_c('path',{attrs:{"d":"M24.19 10h-8.38C12.17 10 10 12.17 10 15.81v8.37c0 3.65 2.17 5.82 5.81 5.82h8.37c3.64 0 5.81-2.17 5.81-5.81v-8.38C30 12.17 27.83 10 24.19 10zm-6.28 14.19c0 .64-.52 1.16-1.17 1.16-.64 0-1.16-.52-1.16-1.16v-3.26c0-.64.52-1.16 1.16-1.16.65 0 1.17.52 1.17 1.16v3.26zm6.51 0c0 .64-.52 1.16-1.16 1.16-.65 0-1.17-.52-1.17-1.16v-8.38c0-.64.52-1.16 1.17-1.16.64 0 1.16.52 1.16 1.16v8.38z","fill":"#fff"}})]),_c('div',{staticClass:"font-main font-semibold text-[24px] text-third leading-[1.33] mb-[11px]"},[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(Number(_vm.statData.sum_total_fish_trade)))+" кг ")]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(Number(_vm.statData.percentage_trade_permission)))+" %")])]),_c('div',{staticClass:"grow font-main font-medium text-[16px] text-fourth leading-[1.5] mb-[11px]"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.my_quota.application.trade.text'))+" ")]),_c('div',{staticClass:"mt-2 text-[12px] italic"},[_vm._v(" "+_vm._s(_vm.$t('headers.data_start_trade_20240701'))+" ")])])]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }