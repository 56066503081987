<template>
	<div
		v-if="series?.length"
		class="bg-white/90 p-[30px] shadow-main rounded-[10px] backdrop-blur-[10px] overflow-y-auto max-h-[470px] scrollbar-blue"
	>
		<template>
			<div class="w-full flex justify-between items-center gap-[10px] mb-[24.18px]">
				<div class="font-main font-semibold text-[18px] text-third uppercase">
					{{ $t('titles.catch_by_fish') }}
				</div>
			</div>
			<div>
				<div
					class="w-full border-b border-b-solid border-b-[#D3E7FB] pb-[10px] flex items-center gap-[65px]"
				>
					<span class="font-main font-normal text-[14px] text-normal text-third">{{
						$t('headers.total')
					}}</span
					><span class="font-main font-bold text-[20px] text-main leading-[1.5]"
						>{{ total.toFixed(2) | formatNumber }} кг</span
					>
				</div>
				<VueApexCharts
					type="bar"
					height="300"
					:options="chartOptions"
					:series="series"
				></VueApexCharts>
			</div>
		</template>
	</div>
</template>

<script>
import { api } from '@/boot/axios'
import VueApexCharts from 'vue-apexcharts'

export default {
	name: 'CatchByFish',
	components: {
		VueApexCharts,
	},
	props: {
		userTag: {
			type: String,
		},
	},
	data() {
		return {
			categories: [],
			series: [],
			total: 0,
		}
	},
	computed: {
		chartOptions() {
			let $this = this
			return {
				chart: {
					type: 'bar',
					height: 350,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 5,
						borderRadiusApplication: 'end',
						distributed: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				markers: {
					shape: 'circle',
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				xaxis: {
					categories: this.categories,
					labels: {
						style: {
							fontSize: '12px',
						},
					},
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return $this.$options.filters.formatNumber(+val.toFixed(2))
						},
					},
				},
				fill: {
					opacity: 1,
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return $this.$options.filters.formatNumber(+val.toFixed(2))
						},
						title: {
							formatter: () => ``,
						},
					},
				},
			}
		},
	},
	mounted() {
		this.getData()
	},
	methods: {
		parseData(data) {
			this.categories = data.map((fish) => fish.fishName)
			this.series = [
				{
					data: data.map((fish) => fish.value),
				},
			]
		},
		async getData() {
			try {
				const response = await api.get(`/dashboard/${this.userTag}/fishes`)

				if (response?.data) {
					this.parseData(response?.data?.data || [])
					this.total = response?.data?.total_value
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>
