<template>
	<div class="wrap__content">
		<div v-if="userId" class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['Account']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<WellCome class="mb-[10px]" />
					<CheckNewReport class="mb-[10px]" />
					<VideoBanner class="mb-[30px]" />
					<StatData :user-tag="getUserTag" class="mb-[10px]" />
					<StatCount v-if="!isShortView" :user-tag="getUserTag" class="mb-[30px]" />
					<div class="grid grid-cols-1 gap-[30px] mb-[30px]">
						<CatchPermissions :user-tag="getUserTag" />
						<!-- <CatchYear /> -->
					</div>
					<div class="grid grid-cols-1 md:grid-cols-2 gap-[30px] mb-[30px]">
						<CatchByFish :user-tag="getUserTag" />
						<ActionsJournal />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WellCome from '../components/WellCome.vue'
import CheckNewReport from '../components/CheckNewReport.vue'
import VideoBanner from '../components/VideoBanner.vue'
import StatData from '../components/StatData.vue'
import StatCount from '../components/StatCount.vue'
import CatchPermissions from '../components/CatchPermissions.vue'
// import CatchYear from '../components/CatchYear.vue'
import CatchByFish from '../components/CatchByFish.vue'
import ActionsJournal from '../components/ActionsJournal.vue'

export default {
	name: 'MainPage',
	components: {
		WellCome,
		CheckNewReport,
		VideoBanner,
		StatData,
		StatCount,
		CatchPermissions,
		// CatchYear,
		CatchByFish,
		ActionsJournal,
	},
	computed: {
		userId() {
			return this.$store?.state?.userObject?.role_id
		},
		getUserTag() {
			if ([3].includes(this.userId)) return `subject`
			return `tir-go`
		},
		isShortView() {
			return ![3].includes(this.userId)
		},
	},
}
</script>
